













import Vue from "vue";

import GuestModel from "@/models/GuestModel";
import OptionModel from "@/models/OptionModel";

import { version } from "../package.json";

import "../node_modules/font-awesome/scss/font-awesome.scss";
import "./assets/scss/style.scss";

export default Vue.extend({
	data() {
		return {
			pageName: this.$route.name as string,
			appDetails: {
				version: version,
				mode: process.env.NODE_ENV === "development" ? "DEV" : "PROD",
			},
			isLoaded: false as boolean,
			isAdminPage: false as boolean,
		};
	},

	mounted() {
		this.isLoaded = true;
		this.isAdminPage = this.$route.meta.isAdminPage || false;
	},

	methods: {
		async loadGuestList() {
			this.$store.commit("resetGuestList");

			const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/attendees";

			const response = await fetch(endpoint, {
				method: "GET",
				cache: "no-store",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
				},
			});
			const data = await response.json();

			const attendees = data.attendees;

			attendees.forEach((guest: any) => {
				const newGuest = new GuestModel(guest);
				this.$store.commit("addGuest", newGuest);
			});

			return attendees;
		},

		async loadOptions() {
			const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/options";

			const response = await fetch(endpoint, {
				method: "GET",
				cache: "no-store",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
				},
			});
			const data = await response.json();

			const options = data.options;

			options.forEach((option: any) => {
				const newOption = new OptionModel({ id: option.id, key: option.key.replace(/\r?\n|\r/g, ""), value: option.value });
				this.$store.commit("addOption", newOption);
			});

			return options;
		},
	},

	watch: {
		$route(to, from) {
			this.pageName = to.name as string;
			this.isAdminPage = to.meta.isAdminPage || false;
		},
	},
});
