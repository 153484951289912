




































import Vue from "vue";
import Toasted from "vue-toasted";
import ChargeModel from "@/models/ChargeModel";
import AuctionItemModel from "@/models/AuctionItemModel";
import filters from "@/global/filters";

Vue.use(Toasted, {
	position: "bottom-left",
	fullWidth: true,
	duration: 8000,
});

export default Vue.extend({
	data() {
		return {
			isLoading: true as boolean,
			auctionItems: [] as AuctionItemModel[],
		};
	},

	async mounted() {
		try {
			let auctionItems = await AuctionItemModel.fetchBy();

			if (Array.isArray(auctionItems)) {
				this.auctionItems = auctionItems;

				for (let item of this.auctionItems) {
					let charges = await ChargeModel.fetchBySku(`auction-item-${item.itemNumber}`);

					if (Array.isArray(charges) && charges.length) {
						let charge = charges[0];
						item.isCharged = !!charge;
						item.bidNumber = charge.bidNumber;
						item.amount = charge.amount;
					}

					item.isLoading = false;
				}
			}

			this.isLoading = false;
		} catch (error) {
			console.error(error);
			this.$toasted.show("Error fetching auction winners", { type: "error" });
		}
	},

	methods: {
		async submitCharge(item: AuctionItemModel) {
			item.isSubmitting = true;

			let charge = new ChargeModel({
				amount: item.amount,
				bidNumber: item.bidNumber,
				name: item.name,
				type: "Auction Item",
				sku: `auction-item-${item.itemNumber}`,
			});

			await charge.create();

			item.isCharged = true;
			item.isSubmitting = false;
		},

		formatCurrency(value: number) {
			return filters.currency(value);
		},
	},

	computed: {
		total(): number {
			let total = 0;

			for (let item of this.auctionItems) {
				if (item.isCharged) {
					total += item.amount;
				}
			}

			return total;
		},
	},

	watch: {},
});
