

































































import Vue from "vue";
import filters from "@/global/filters";
import Toasted from "vue-toasted";
import GameModel from "@/models/GameModel";
import GuestModel from "@/models/GuestModel";
import ChargeModel from "@/models/ChargeModel";

Vue.use(Toasted, {
	position: "bottom-left",
	fullWidth: true,
	duration: 8000,
});

export default Vue.extend({
	data() {
		return {
			isLoading: true as boolean,
			selectedGame: null as null | GameModel,
			bidNumber: null as null | number,
			amount: null as null | number,
			availableSideGames: [] as GameModel[],
			updating: false,
			gameRoute: null as null | string,
			gameHistory: [] as ChargeModel[],
			isLoadingHistory: false as boolean,
			guests: [] as false | GuestModel[],
		};
	},

	async created() {
		this.gameRoute = this.$route.params.gameId;
		this.availableSideGames = await GameModel.fetchAll();
		this.guests = await GuestModel.fetchBy();

		if (!!this.gameRoute && !!this.availableSideGames.length) {
			let foundGame = this.availableSideGames.find((game: GameModel) => game.name === this.gameRoute);

			if (!!foundGame) {
				this.selectedGame = foundGame;
				this.amount = this.selectedGame.costPerPlay;
			}
		}

		this.gameHistory = await this.fetchHistory();

		this.isLoading = false;

		setTimeout(() => this.setFocus(), 1000);

		setInterval(async () => {
			this.gameHistory = await this.fetchHistory(true);
		}, 60000);
	},

	methods: {
		async fetchHistory(overwrite: boolean = false): Promise<ChargeModel[]> {
			let history = [] as ChargeModel[];

			if (!overwrite) {
				this.isLoadingHistory = true;
			}

			if (!!this.selectedGame && !!this.guests) {
				history = await ChargeModel.fetchByGame(this.selectedGame.name);

				if (!!history.length) {
					for (let item of history) {
						//let guests = await GuestModel.fetchByBidNumber(item.bidNumber);
						let guests = this.guests.filter((guest: GuestModel) => guest.bidNumber === item.bidNumber);
						item.guest = guests[0];
					}
				}
			}

			if (!overwrite) {
				setTimeout(() => (this.isLoadingHistory = false), 1000);
			} else {
				this.isLoadingHistory = false;
			}

			return history;
		},

		async addCharge() {
			if (this.bidNumber && this.selectedGame && this.amount && this.guests) {
				this.updating = true;

				//let guests = (await GuestModel.fetchByBidNumber(this.bidNumber)) as GuestModel[];
				let guests = this.guests.filter((guest: GuestModel) => guest.bidNumber === this.bidNumber);

				if (!!guests.length) {
					let guest = guests[0];

					const charge = new ChargeModel({
						sku: this.selectedGame.name,
						name: this.selectedGame.title,
						bidNumber: guest.bidNumber,
						amount: this.amount,
						type: "Side Game",
						guest: guest,
					});

					await charge.create();

					this.$toasted.show(`Successfully charged ${this.formatCurrency(charge.amount)} to Bidder #${charge.bidNumber}`, { type: "success" });

					//this.gameHistory = await this.fetchHistory(true);
					this.gameHistory.push(charge);
				} else {
					this.$toasted.show("Bid number not found", { type: "error" });
				}

				this.updating = false;

				this.reset();
			} else {
				alert("You must enter a valid bid number");
			}
		},

		async getGuestName(charge: ChargeModel) {
			let guests = await GuestModel.fetchByBidNumber(charge.bidNumber);
			return guests[0].lname;
		},

		setFocus() {
			const el = this.$refs.bidNumberInput as HTMLInputElement;

			if (el) {
				el.addEventListener("click", () => {
					setTimeout(() => el.focus(), 100);
				});

				el.click();
			}
		},

		reset() {
			this.bidNumber = null;
			this.setFocus();
		},

		formatCurrency(value: string | number) {
			return filters.currency(value);
		},
	},

	computed: {
		reverseGameHistory(): ChargeModel[] {
			return !!this.gameHistory ? this.gameHistory.slice().reverse() : [];
		},
	},

	watch: {
		async selectedGame(to: GameModel, from: GameModel) {
			if (to !== from) {
				this.amount = to.costPerPlay;
				this.gameHistory = await this.fetchHistory();
				//this.$router.push({ name: "SideGameDetail", params: { gameId: to.name } });
			}
		},
	},
});
