













































import Vue from "vue";
import VueMask from "v-mask";
import sms from "@/global/sms";
import variables from "@/global/variables";

Vue.use(VueMask);

// Data Classes
import GuestModel from "@/models/GuestModel";

// Components
import GuestComponent from "@/components/GuestComponent.vue";
import OptionModel from "@/models/OptionModel";

export default Vue.extend({
	data() {
		return {
			error: null as null | string,
			phoneNumber: null as null | string,
			guests: null as null | Array<GuestModel>,
			isRegistering: false as boolean,
			confirm: null as null | boolean,
		};
	},

	methods: {
		async registerGuest() {
			if (!!this.phoneNumber) {
				this.isRegistering = true;

				const formattedPhone = this.phoneNumber.replace(/\D/g, "");
				const guests = await GuestModel.fetchByPhone(formattedPhone);

				if (!!guests && !!guests.length) {
					this.confirm = await this.sendRegistrationText(guests);
				} else {
					this.error = "No guests found. Please verify that the phone number is correct.";
				}

				this.isRegistering = false;
			}
		},

		async sendRegistrationText(guests: GuestModel[]): Promise<boolean> {
			let success = true;

			const from = await OptionModel.getByKey("sms_from_phone_number");
			const message = await OptionModel.getByKey("sms_registration_message");

			if (!!from && !!message) {
				for (const guest of guests) {
					if (!!guest.phone) {
						const mergedMessage = sms.merge(
							{
								registerLink: variables.appUrl + "/register/" + guest.bidNumber,
							},
							message.value
						);

						try {
							await sms.send(guest.phone, from.value, `${mergedMessage}`);
						} catch (error) {
							success = false;
						}

						return success;
					}
				}
			} else {
				return false;
			}

			return true;
		},

		copyPhone(phone: string) {
			if (this.guests) {
				this.guests.forEach((guest) => {
					guest.phone = phone;
				});
			}
		},

		reset() {
			this.phoneNumber = null;
			this.guests = [];
		},
	},

	computed: {
		isValidData(): boolean {
			if (this.guests) {
				const invalidValues = this.guests.some((guest: GuestModel) => !guest.phone || guest.phone.length !== 10);

				return !invalidValues;
			}

			return false;
		},
	},

	components: {
		GuestComponent,
	},
});
