

































import Vue from "vue";

import _ from "lodash";
import moment from "moment";

// Data Classes
import GuestModel from "../models/GuestModel";

// Components
import PhoneInputComponent from "@/components/PhoneInputComponent.vue";

// Global Functions
import sms from "@/global/sms";
import variables from "@/global/variables";

import Toasted from "vue-toasted";
import OptionModel from "@/models/OptionModel";

Vue.use(Toasted, {
	position: "bottom-left",
	fullWidth: true,
	duration: 8000,
});

export default Vue.extend({
	name: "Guest" as string,

	props: {
		guest: {
			type: GuestModel,
		},
		count: {
			type: Number,
		},
	},

	data() {
		return {
			hasChanges: false as boolean,
			isUpdating: false as boolean,
		};
	},

	created() {},

	mounted() {
		this.hasChanges = false;
	},

	methods: {
		copyPhoneClick(phone: string) {
			this.$emit("copy-phone", phone);
		},

		registerGuest() {
			if (!this.isRegistered || (this.isRegistered && confirm("Are you sure you want to re-register this guest?\n\nThis will update their information, but they will receive another text message."))) {
				this.isUpdating = true;

				this.sendRegistrationText()
					.then((response: any) => {
						this.guest.registered = moment().format();

						this.updateGuest()
							.then((response: any) => {
								this.$toasted.success("You have successfully registered " + this.guest.fname + " " + this.guest.lname + " (#" + this.guest.bidNumber + ")");

								this.reset();
							})
							.catch((error: any) => {
								console.error(error);

								this.$toasted.error("There was an error saving the guest's information. Please try again.", {
									action: {
										text: "Get Help!",
										onClick: (e, toastObject) => {
											this.sendHelpSMS("HELP NEEDED AT REGISTRATION!");
										},
									},
								});

								this.reset();
							});
					})
					.catch((error: any) => {
						console.error(error);

						this.$toasted.error("There was an error sending the text message. Please try again.", {
							action: {
								text: "Get Help!",
								onClick: (e, toastObject) => {
									this.sendHelpSMS("HELP NEEDED AT REGISTRATION!");
								},
							},
						});

						this.reset();
					});
			}
		},

		updateGuest() {
			return new Promise((resolve, reject) => {
				if (this.isPhoneValid) {
					this.$store
						.dispatch("updateGuest", this.guest)
						.then((updatedGuest: GuestModel) => {
							resolve(updatedGuest);
						})
						.catch((error) => {
							reject(error);
						});
				}
			});
		},

		sendRegistrationText() {
			return new Promise(async (resolve, reject) => {
				const from = await OptionModel.getByKey("sms_from_phone_number");
				const message = await OptionModel.getByKey("sms_welcome_message");

				if (this.guest.phone && this.isPhoneValid && !!from && !!message) {
					let mergedMessage = sms.merge(
						{
							checkoutLink: variables.appUrl + "checkout/" + this.guest.bidNumber,
							newline: "\n\n",
						},
						message.value
					);

					sms.send(this.guest.phone, from.value, mergedMessage)
						.then((resp: any) => {
							resolve(resp);
						})
						.catch((error: any) => {
							reject(error);
						});
				}
			});
		},

		async sendHelpSMS(message: string, helpPhoneNumber: string = "4402416892") {
			const from = await OptionModel.getByKey("sms_from_phone_number");
			sms.send(helpPhoneNumber, from!.value, message);
		},

		reset() {
			this.isUpdating = false;
			this.hasChanges = false;
		},
	},

	computed: {
		isRegistered(): boolean {
			return !!this.guest.registered;
		},

		isPhoneValid(): boolean {
			if (this.guest.phone) {
				const strVal = this.guest.phone.toString();
				return strVal.length === 10;
			}

			return false;
		},
	},

	watch: {
		guest: {
			handler: function() {
				this.hasChanges = true;
			},
			deep: true,
		},
	},

	components: {
		PhoneInputComponent,
	},
});
