






































import Vue from "vue";
import Toasted from "vue-toasted";
import ChargeModel from "@/models/ChargeModel";
import filters from "@/global/filters";

Vue.use(Toasted, {
	position: "bottom-left",
	fullWidth: true,
	duration: 8000,
});

interface BidGroup {
	label: number;
	bids: ChargeModel[];
}

export default Vue.extend({
	data() {
		return {
			bidQueue: [] as ChargeModel[],
			bidNumber: null as null | number,
			amount: null as null | number,
			successes: [] as ChargeModel[],
			errors: [] as ChargeModel[],
		};
	},

	mounted() {
		let bids = window.localStorage.getItem("bids");

		if (!!bids) {
			for (let bid of JSON.parse(bids) as ChargeModel[]) {
				this.bidQueue.push(new ChargeModel(bid));
			}
		}

		if (!this.amount) {
			this.amount = !!this.bidQueue.length ? this.bidQueue[this.bidQueue.length - 1]!.amount : 5000;
		}
	},

	methods: {
		addBid() {
			if (!!this.bidNumber && !!this.amount) {
				let bid = new ChargeModel({
					sku: "call-to-the-heart",
					name: "Call to the Heart",
					bidNumber: this.bidNumber,
					amount: this.amount,
					id: this.bidQueue.length + 1,
					type: "Call to the Heart",
				});
				this.bidQueue.push(bid);
				this.bidNumber = null;
			}
		},

		removeBid(bid: ChargeModel) {
			let index = this.bidQueue.indexOf(bid);
			this.bidQueue.splice(index, 1);
		},

		saveBidQueue() {
			window.localStorage.setItem("bids", JSON.stringify(this.bidQueue));
		},

		async submitBids() {
			if (confirm("Are you sure you want to submit these bids as charges?")) {
				for (let i = 0; i < this.bidQueue.length; i++) {
					try {
						let charge = await this.bidQueue[i].create();
						this.successes.push(charge);
						this.bidQueue.splice(i, 1); // remove the processed bid
						i--; // decrement the index as the array length has changed
					} catch (error) {
						this.errors.push(this.bidQueue[i]);
					}
				}

				//this.bidQueue = [];

				if (this.errors.length === 0) {
					this.$toasted.show("Bids submitted as charges", { type: "success" });
				} else {
					this.$toasted.show("Some bids failed to submit", { type: "error" });
				}
			}
		},

		formatCurrency(value: number) {
			return filters.currency(value);
		},
	},

	computed: {
		bidGroups(): any[] | never[] {
			let groups = this.bidQueue.reduce((acc: never[] | any[], bid: ChargeModel) => {
				// Find the group for this bid amount
				let group = acc.find((item: any) => item.label === bid.amount) as BidGroup | undefined;

				// If the group doesn't exist, create it
				if (!group) {
					group = { label: bid.amount as number, bids: [] as ChargeModel[] };
					acc.push(group as never);
				}

				// Push the bid into the group
				group.bids.push(bid);

				return acc;
			}, []);

			return groups;
		},

		sortedBidGroups() {
			let bidGroups = this.bidGroups as any;
			return bidGroups.sort((a: BidGroup, b: BidGroup) => a.label - b.label);
		},

		bidTotal(): number {
			let total = 0;

			for (const bid of this.bidQueue) {
				total += bid.amount as number;
			}

			return total;
		},
	},

	watch: {
		bidQueue: {
			handler() {
				this.saveBidQueue();
			},
			deep: true,
		},
	},
});
