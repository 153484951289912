import Vue from "vue";
import Vuex, { Store } from "vuex";
import axios from "axios";

import GuestModel from "@/models/GuestModel";
import OptionModel from "@/models/OptionModel";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		guests: [] as Array<GuestModel>,
		options: [] as Array<OptionModel>,
	},

	mutations: {
		addGuest(state, guest: GuestModel) {
			state.guests.push(guest);
		},

		resetGuestList(state) {
			state.guests = [];
		},

		updateGuest(state, updatedGuest: GuestModel) {
			const foundGuest = state.guests.find((guest: GuestModel) => guest.id === updatedGuest.id);
			if (foundGuest) Object.assign(foundGuest, updatedGuest);
		},

		addOption(state, option: OptionModel) {
			state.options.push(option);
		},
	},

	actions: {
		updateGuest({ commit, state }, updatedGuest: GuestModel) {
			return new Promise((resolve, reject) => {
				const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/attendees/" + updatedGuest.id;

				const data = {
					attendee: {
						bidNumber: updatedGuest.bidNumber,
						firstName: updatedGuest.fname,
						lastName: updatedGuest.lname,
						tableNumber: updatedGuest.tableNo,
						phoneNumber: updatedGuest.phone,
						registered: updatedGuest.registered,
					},
				};

				axios({
					method: "put",
					url: endpoint,
					data: data,
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
					},
				})
					.then((response) => {
						commit("updateGuest", updatedGuest);
						resolve(updatedGuest);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},

	getters: {
		getOptionValue: (state) => (optionKey: string) => {
			const foundOption = state.options.find((option: OptionModel) => option.key === optionKey);
			return foundOption ? foundOption.value : null;
		},

		getGuestsByBidNumber: (state) => (bidNumber: number) => {
			return state.guests.filter((guest: GuestModel) => guest.bidNumber == bidNumber);
		},

		getGuestsByPhone: (state) => (phone: string) => {
			return state.guests.filter((guest: GuestModel) => guest.phone == phone);
		},
	},
	modules: {},
});
