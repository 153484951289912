import moment from "moment";
export default class GuestModel {
	id: number;
	bidNumber: number;
	fname: string;
	lname: string;
	phone: string | null;
	tableNo: number | null;
	registered: string | null;

	constructor(args: any) {
		this.id = args.id || null;
		this.bidNumber = args.bidNumber || null;
		this.fname = args.firstName || null;
		this.lname = args.lastName || null;
		this.phone = args.phoneNumber || null;
		this.tableNo = args.tableNumber || null;
		this.registered = args.registered || null;
	}

	register(): GuestModel {
		this.registered = moment().format();
		return this;
	}

	async update(): Promise<boolean | GuestModel> {
		const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/attendees/" + this.id;

		const body = {
			attendee: {
				bidNumber: this.bidNumber,
				firstName: this.fname,
				lastName: this.lname,
				tableNumber: this.tableNo,
				phoneNumber: this.phone,
				registered: this.registered,
			},
		};

		const response = await fetch(endpoint, {
			method: "PUT",
			cache: "no-store",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const data = await response.json();

		if (!!data.errors) return false;

		return this;
	}

	static async fetchAll(): Promise<GuestModel[]> {
		return await GuestModel.fetchBy();
	}

	static async fetchBy(key: null | string = null, value: null | string | number = null): Promise<GuestModel[]> {
		let attendees: GuestModel[] = [];

		let endpoint = `${process.env.VUE_APP_SHEETY_ENDPOINT}/attendees`;

		if (key && value) {
			endpoint += `?filter[${key}]=${value}`;
		}

		const response = await fetch(endpoint, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const data = await response.json();

		if (!!data.errors) return [];

		return data.attendees.map((attendee: GuestModel) => new GuestModel(attendee));
	}

	static async fetchByPhone(phone: string): Promise<GuestModel[]> {
		return await GuestModel.fetchBy("phoneNumber", phone);
	}

	static async fetchByBidNumber(bidNumber: number): Promise<GuestModel[]> {
		return await GuestModel.fetchBy("bidNumber", bidNumber);
	}
}
