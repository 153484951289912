









import Vue from "vue";

export default Vue.extend({
	name: "PhoneInput" as string,

	props: {
		id: {
			type: String
		},
		placeholder: {
			type: String
		},
		value: {
			type: [Number, String]
		},
		required: {
			type: Boolean,
			default: false
		},
		count: {
			type: Number
		}
	},

	data() {
		return {
			isChanged: false as boolean,
			isUpdating: false as boolean,
			sanitizedPhoneValue: "0" as string
		};
	},

	methods: {
		updateValue: function(value: string) {
			this.isChanged = true;
			this.$emit("input", value);
		}
	},

	computed: {
		isValid(): boolean {
			return this.phoneNumber.length === 10;
		},

		phoneNumber(): string {
			return this.value ? this.value.toString() : "";
		}
	},

	watch: {
		value() {
			if (!!this.phoneNumber) {
				let match = this.phoneNumber
					.replace(/-/g, "")
					.match(/(\d{1,10})/g);

				if (match && match.length) {
					this.sanitizedPhoneValue = match[0];
					this.updateValue(this.sanitizedPhoneValue);
				}
			}
		}
	}
});
