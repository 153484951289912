
































































import Vue from "vue";
import GuestModel from "@/models/GuestModel";
import sms from "@/global/sms";
import OptionModel from "@/models/OptionModel";
import ChargeModel from "@/models/ChargeModel";
import MessageModel from "@/models/MessageModel";

const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

interface MessageGroup {
	id: number;
	name: string;
	list: GuestModel[];
	isLoading: boolean;
}

interface MergeTag {
	tag: string;
	description: string;
}

export default Vue.extend({
	data() {
		return {
			isLoading: true as boolean,
			guests: [] as false | GuestModel[],
			charges: [] as false | ChargeModel[],
			message: "" as string,
			maxCharacters: 140 as number,
			duplicates: [] as Array<string>,
			isSending: false as boolean,
			processed: [] as GuestModel[],
			errors: [] as GuestModel[],
			queue: [] as GuestModel[],
			selectedGroup: null as null | MessageGroup,
			groups: null as null | MessageGroup[],
			showMessageHistory: false as boolean,
			mergeTags: [
				{
					tag: "{bidderNumber}",
					description: "The guest's bidder number",
				},
				{
					tag: "{checkoutLink}",
					description: "The link to the guest's checkout page",
				},
				{
					tag: "{registerLink}",
					description: "The link to the guest's registration page",
				},
				{
					tag: "{firstName}",
					description: "The guest's first name",
				},
				{
					tag: "{lastName}",
					description: "The guest's last name",
				},
			] as MergeTag[],
			messageHistory: [] as MessageModel[],
		};
	},

	async created() {
		this.guests = await GuestModel.fetchBy();
		this.charges = await ChargeModel.fetchBy();

		if (!!this.guests && !!this.guests.length) {
			const allGuests = this.guests.filter((guest: GuestModel) => !!guest.phone);
			const registeredGuests = this.guests.filter((guest: GuestModel) => !!guest.phone && !!guest.registered);
			const unregisteredGuests = this.guests.filter((guest: GuestModel) => !!guest.phone && !!!guest.registered);

			this.groups = [
				{ id: 1, name: `All Guests`, list: allGuests },
				{ id: 2, name: `Registered Guests`, list: registeredGuests },
				{ id: 3, name: `Unregistered Guests`, list: unregisteredGuests },
				{ id: 4, name: `Unpaid Guests`, list: this.unpaidGuests },
				//{ id: 5, name: "Paid Guests", list: [] },
			] as MessageGroup[];

			if (!this.selectedGroup) {
				this.selectedGroup = this.groups[0];
			}
		}

		this.messageHistory = await MessageModel.fetchBy();
		this.messageHistory = this.messageHistory.sort((a: MessageModel, b: MessageModel) => new Date(b.created).getTime() - new Date(a.created).getTime());

		this.isLoading = false;
	},

	methods: {
		async sendMessages() {
			const confirm = window.confirm(`Are you sure you want to send this message to ${this.queue.length} guests?`);

			if (confirm && this.selectedGroup && this.message) {
				const from = await OptionModel.getByKey("sms_from_phone_number");

				if (!!from) {
					this.queue = this.selectedGroup.list;
					this.isSending = true;
					this.processed = [];

					let message = new MessageModel({
						message: this.message,
						group: this.selectedGroup.name,
						count: this.queue.length,
						from: from.value,
					});

					this.messageHistory.push(await message.create());

					for (const item in this.queue) {
						let guest = this.queue[item];

						if (guest.phone) {
							let mergedMessage = sms.merge(
								{
									bidderNumber: guest.bidNumber,
									checkoutLink: `${process.env.VUE_APP_URL}/checkout/${guest.bidNumber}`,
									registerLink: `${process.env.VUE_APP_URL}/register/${guest.bidNumber}`,
									firstName: guest.fname,
									lastName: guest.lname,
								},
								this.message
							);

							try {
								const smsResponse = await sms.send(guest.phone, from.value, !!mergedMessage ? mergedMessage : "");

								if (smsResponse) {
									this.processed.push(guest);
								}
							} catch (e) {
								this.errors.push(guest);
							}

							await timer(1000);
						}
					}

					await timer(10000);

					this.processed = [];
					//this.message = "";
					this.isSending = false;

					if (this.$options.filters) {
						//this.$toasted.success("Successfully sent message to " + this.$options.filters.pluralize(this.uniqueRegisteredPhoneNumbers.length, "Registered Guest", "s"));
					}
				}
			} else {
				console.error("No registered guests to send to");
			}
		},

		copyMessage(message: MessageModel) {
			this.selectedGroup = this.groups!.find((group: MessageGroup) => group.name === message.group) || null;
			this.message = message.message;
			this.showMessageHistory = false;
		},

		selectGroup(group: any) {},

		reset() {
			this.message = "";
		},

		injectStringAtCursor(text: string) {
			const textarea = this.$refs.message as HTMLTextAreaElement;
			const start = textarea.selectionStart;
			const end = textarea.selectionEnd;

			// Insert the text at the cursor position
			textarea.value = textarea.value.substring(0, start) + text + textarea.value.substring(end);

			// Move the cursor to the end of the inserted text
			textarea.selectionStart = textarea.selectionEnd = start + text.length;

			textarea.focus();

			textarea.dispatchEvent(new Event("input"));
		},
	},

	computed: {
		progress(): number {
			if (!!this.queue.length) {
				return this.processed.length / this.queue.length;
			}

			return 0;
		},

		unregisteredGuests(): GuestModel[] {
			return !!this.guests ? this.guests.filter((guest: GuestModel) => !!!guest.registered) : [];
		},

		registeredGuests(): GuestModel[] {
			return !!this.guests ? this.guests.filter((guest: GuestModel) => !!guest.registered) : [];
		},

		unpaidCharges(): ChargeModel[] {
			return !!this.charges ? this.charges.filter((charge: ChargeModel) => !!!charge.paymentId) : [];
		},

		unpaidChargesByBidder(): ChargeModel[] {
			return !!this.unpaidCharges ? this.unpaidCharges.filter((charge: ChargeModel, index: number, self: ChargeModel[]) => self.findIndex((c: ChargeModel) => c.bidNumber === charge.bidNumber) === index) : [];
		},

		unpaidGuests(): GuestModel[] {
			let unpaidGuests = [] as GuestModel[];

			if (!!this.guests && !!this.unpaidChargesByBidder) {
				for (const charge of this.unpaidChargesByBidder) {
					let guests = this.guests.filter((guest: GuestModel) => !!guest.phone && guest.bidNumber === charge.bidNumber);

					if (!!guests && !!guests.length) {
						let guest = guests[0];

						if (!unpaidGuests.some((g: GuestModel) => guest.bidNumber === g.bidNumber)) {
							for (const item in guests) {
								unpaidGuests.push(guests[item]);
							}
						}
					}
				}
			}

			return unpaidGuests;
		},
	},

	filters: {
		pluralize(count: number, text: string, suffix: string = "s") {
			return count + " " + (count > 1 ? text + suffix : text);
		},
	},

	watch: {
		selectedGroup(to: MessageGroup, from: MessageGroup) {
			if (to !== from) {
				this.queue = to.list;
			}
		},
	},
});
