export default class AuctionItemModel {
	id: number;
	itemNumber: number;
	name: string;
	amount: number;
	bidNumber: number;
	paymentId: string;
	description: string;
	notes: string;
	isCharged: boolean;
	isSubmitting: boolean;
	isLoading: boolean;

	constructor(args: any) {
		this.id = args.id || null;
		this.itemNumber = args.itemNumber || null;
		this.name = args.name || null;
		this.amount = args.amount || null;
		this.bidNumber = args.bidNumber || null;
		this.paymentId = args.paymentId || null;
		this.description = args.description || null;
		this.notes = args.notes || null;
		this.isCharged = false;
		this.isSubmitting = false;
		this.isLoading = true;

		if (!!this.name) this.name.trim();
	}

	static async fetchBy(): Promise<AuctionItemModel[]> {
		const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/auctionItems";

		const response = await fetch(endpoint, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const json = await response.json();

		if (!!json.errors) throw new Error(json.errors);

		return json.auctionItems.map((item: any) => new AuctionItemModel(item)).filter((item: AuctionItemModel) => !!item.itemNumber);
	}
}
