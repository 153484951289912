





















import Vue from "vue";
import { PropType } from "vue";

// Global Functions
import payments from "@/global/payments";
import filters from "@/global/filters";
import GuestModel from "@/models/GuestModel";
import ChargeModel from "@/models/ChargeModel";

export default Vue.extend({
	name: "PaymentForm" as string,

	props: {
		amount: {
			type: Number,
		},
		guest: {
			type: Object as PropType<GuestModel>,
		},
		charges: {
			type: Array as PropType<ChargeModel[]>,
		},
		isReady: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isProcessing: false as boolean,
			stripeClient: null as any,
			cardNumberInput: null as any,
			cardExpiryInput: null as any,
			cardCvcInput: null as any,
		};
	},

	mounted() {
		// Set up our Stripe Client for building the form fields and generating the proper token
		this.stripeClient = payments.getClient();

		const elements = this.stripeClient.elements();

		const elementOptions = {
			classes: {
				base: "",
			},
			style: {
				base: {
					backgroundColor: "#FFF",
				},
			},
		};

		this.cardNumberInput = elements.create("cardNumber", elementOptions);
		this.cardNumberInput.mount(".payment-form__input--number");

		this.cardExpiryInput = elements.create("cardExpiry", elementOptions);
		this.cardExpiryInput.mount(".payment-form__input--expiry");

		this.cardCvcInput = elements.create("cardCvc", elementOptions);
		this.cardCvcInput.mount(".payment-form__input--cvc");
	},

	methods: {
		submitPayment(event: any) {
			this.isProcessing = true;

			this.$emit("processing", true);

			this.stripeClient.createToken(this.cardNumberInput).then((result: any) => {
				if (result.error) {
					// Inform the customer that there was an error.
					var errorElement = document.getElementById("card-errors");

					if (errorElement) {
						errorElement.textContent = result.error.message;
					}

					this.isProcessing = false;
				} else {
					const token = result.token.id;
					const amount = filters.formatStripeCurrency(this.amount);
					const description = `Emerald Ball 2024 Invoice - Bid #${this.guest.bidNumber}`;

					if (token && amount && description) {
						payments
							.process(token, amount, description)
							.then((response: any) => {
								this.$emit("success", response);
							})
							.catch((error) => {
								this.$emit("error", error);
							})
							.finally(() => {
								this.isProcessing = false;
								this.$emit("processing", false);
							});
					}
				}
			});
		},

		currencyFormat(value: any) {
			return filters.formatCurrencyStr(value);
		},

		stripeFormat(value: any) {
			return filters.formatStripeCurrency(value);
		},
	},

	computed: {},

	watch: {},

	components: {},
});
