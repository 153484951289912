export default class OptionModel {
	id: number;
	key: string;
	value: string;

	constructor(args: any) {
		this.id = args.id || null;
		this.key = args.key || null;
		this.value = args.value || null;
	}

	static async getByKey(key: string): Promise<OptionModel | null> {
		const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/options/?filter[key]=" + key;

		const response = await fetch(endpoint, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});
		const data = await response.json();

		if (data.error) return null;

		return data.options[0];
	}
}
