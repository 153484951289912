
















































import filters from "@/global/filters";
import ChargeModel from "@/models/ChargeModel";
import GuestModel from "@/models/GuestModel";
import Vue from "vue";

export default Vue.extend({
	data() {
		return {
			isLoadingStats: true as boolean,
			guests: [] as GuestModel[],
			charges: [] as ChargeModel[],
		};
	},

	async created() {
		let guests = await GuestModel.fetchBy();
		let charges = await ChargeModel.fetchBy();

		this.guests = guests || [];
		this.charges = charges || [];

		this.isLoadingStats = false;
	},

	methods: {
		formatCurrency(value: number) {
			return filters.currency(value);
		},
	},

	computed: {
		unregisteredGuests(): GuestModel[] {
			return this.guests.filter((guest: GuestModel) => !!!guest.registered);
		},

		totalCharges(): number {
			return this.charges.reduce((total: number, charge: ChargeModel) => total + charge.amount, 0);
		},
	},
});
