import GuestModel from "./GuestModel";
import moment from "moment";
export default class ChargeModel {
	id: null | number;
	name: string;
	description: string;
	amount: number;
	bidNumber: number;
	paymentId: null | string;
	sku: string;
	type: string;
	tag!: string;
	label!: string;
	paid!: string;
	guest: GuestModel;
	created: string;
	updated: string;

	constructor(args: any) {
		this.id = args.id || null;
		this.sku = args.sku || null;
		this.name = args.name || null;
		this.description = args.description || null;
		this.amount = args.amount || null;
		this.bidNumber = args.bidNumber || null;
		this.paymentId = args.paymentId || null;
		this.type = args.type || null;
		this.guest = args.guest || null;
		this.created = args.created || moment().format();
		this.updated = args.updated || moment().format();
	}

	markPaid(chargeId: string) {
		this.paymentId = chargeId;
		return this;
	}

	async create() {
		const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/charges";

		const data = {
			charge: {
				...this,
				created: moment().format(),
				updated: moment().format(),
			},
		};

		const response = await fetch(endpoint, {
			method: "POST",
			cache: "no-store",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const json = await response.json();

		return new ChargeModel(json.charge);
	}

	async update() {
		const endpoint = `${process.env.VUE_APP_SHEETY_ENDPOINT}/charges/${this.id}`;

		const data = {
			charge: {
				...this,
				updated: moment().format(),
			},
		};

		const response = await fetch(endpoint, {
			method: "PUT",
			cache: "no-store",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		return response.json();
	}

	static async fetchBy(key: null | string = null, value: null | string = null): Promise<ChargeModel[]> {
		let endpoint = `${process.env.VUE_APP_SHEETY_ENDPOINT}/charges`;

		if (!!key && !!value) {
			endpoint += `?filter[${key}]=${value}`;
		}

		const response = await fetch(endpoint, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const data = await response.json();

		if (!!data.errors) return [];

		return data.charges.map((charge: any) => new ChargeModel(charge));
	}

	static async fetchByGame(gameId: string): Promise<ChargeModel[]> {
		return await ChargeModel.fetchBy("sku", gameId);
	}

	static async fetchByBidNumber(bidNumber: number): Promise<ChargeModel[]> {
		return await ChargeModel.fetchBy("bidNumber", bidNumber.toString());
	}

	static async fetchBySku(sku: string): Promise<ChargeModel[]> {
		return await ChargeModel.fetchBy("sku", sku);
	}
}
