import axios from "axios";
import variables from "./variables";

declare const Stripe: any;

const payments = {
	getClient(): any {
		return Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
	},

	process(stripeToken: string, amount: number, description: string = "") {
		return new Promise((resolve, reject) => {
			const postdata = {
				stripeToken: stripeToken,
				amount: amount,
				description: description,
			};

			axios({
				method: "post",
				url: variables.apiEndpoint + "/payment.php",
				data: postdata,
			}).then((response) => {
				if (response.data.success) {
					resolve(response.data);
				} else {
					reject(response.data);
				}
			});
		});
	},
};

export default payments;
