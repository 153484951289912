import Vue from "vue";
import VueRouter from "vue-router";

// Page Components
import Home from "@/views/Home.vue";
import Registration from "@/views/Registration.vue";
import Register from "@/views/Register.vue";
import Checkout from "@/views/Checkout.vue";
import SideGames from "@/views/SideGames.vue";
import MessageCenter from "@/views/MessageCenter.vue";
import Admin from "@/views/Admin.vue";
import CallToTheHeart from "@/views/CallToTheHeart.vue";
import InvoiceSearch from "@/views/InvoiceSearch.vue";
import AuctionWinners from "@/views/AuctionWinners.vue";
import Reports from "@/views/Reports.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/register",
		name: "Registeration",
		component: Home,
	},
	{
		path: "/checkout",
		name: "Checkout",
		component: Checkout,
	},
	{
		path: "/checkout/:bidNumber",
		name: "CheckoutDetail",
		component: Checkout,
	},
	{
		path: "/register/:bidNumber",
		name: "GuestRegistration",
		component: Register,
	},
	{
		path: "/side-games",
		name: "SideGames",
		component: SideGames,
	},
	{
		path: "/side-games/:gameId",
		name: "SideGameDetail",
		component: SideGames,
	},
	{
		path: "/admin",
		name: "Admin",
		component: Admin,
	},
	{
		path: "/admin/invoice-search",
		name: "InvoiceSearch",
		component: InvoiceSearch,
		meta: {
			isAdminPage: true,
		},
	},
	{
		path: "/admin/registration",
		name: "AdminRegistration",
		component: Registration,
		meta: {
			isAdminPage: true,
		},
	},
	{
		path: "/admin/call-to-the-heart",
		name: "AdminCallToTheHeart",
		component: CallToTheHeart,
		meta: {
			isAdminPage: true,
		},
	},
	{
		path: "/admin/message-center",
		name: "AdminMessageCenter",
		component: MessageCenter,
		meta: {
			isAdminPage: true,
		},
	},
	{
		path: "/admin/auction-winners",
		name: "AdminAuctionWinners",
		component: AuctionWinners,
		meta: {
			isAdminPage: true,
		},
	},
	{
		path: "/admin/reports",
		name: "AdminReports",
		component: Reports,
		meta: {
			isAdminPage: true,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
