














import Vue from "vue";
import GuestModel from "@/models/GuestModel";

export default Vue.extend({
	data() {
		return {
			isSearching: false as boolean,
			msg: null as null | string,
			guestBidNumberInput: null as null | number,
			guests: null as null | GuestModel[],
			isPreregistering: true as boolean,
			isRegistering: true as boolean,
			bidNumber: null as null | number,
			errorMsg: null as null | string,
		};
	},

	created() {
		this.bidNumber = parseInt(this.$route.params.bidNumber);
	},

	async mounted() {
		// If the bid number is present, then check if the guests are already registered
		if (!!this.bidNumber) {
			// Check if the guests are already registered
			let foundGuests = await GuestModel.fetchByBidNumber(this.bidNumber);

			// If the guests are already registered, then set the flag to true
			if (!!foundGuests && !!foundGuests.length) {
				this.isRegistering = true;

				// Set the registered guests
				for (const guest of foundGuests) {
					if (!guest.registered) {
						await guest.register().update();
					}
				}

				setTimeout(() => {
					// Set the registering flag to false
					this.isRegistering = false;
				}, 1500);
			} else {
				this.errorMsg = "No guests found. Please try again or contact the Leadership Geauga office at <a href='#'>440-286-8115</a>.";
				this.isRegistering = false;
			}
		}
	},

	methods: {
		async searchGuests() {
			this.guests = null;
			this.isSearching = true;

			if (!!this.guestBidNumberInput) {
				let foundGuests = await GuestModel.fetchByBidNumber(this.guestBidNumberInput);

				if (!!foundGuests && !!foundGuests.length) {
					this.guests = foundGuests;
					this.msg = null;
				} else {
					this.msg = "No guests found. Please verify that the bid number is correct.";
				}
			}

			this.isSearching = false;
		},
	},
});
