import axios from "axios";

import variables from "@/global/variables";

const sms = {
	send(to: string, from: string, message: string): Promise<any> {
		return new Promise((resolve, reject) => {
			axios({
				method: "post",
				url: variables.apiEndpoint + "/send-sms.php",
				data: {
					sms_to: to,
					sms_from: from,
					sms_msg: message,
				},
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
				.then((response) => {
					if (response.status === 200) resolve(response);
					else reject(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	merge(mergeMap: any, content: string): string {
		mergeMap.newline = "\n\n";

		const map = Object.entries(mergeMap);

		let result = content;

		for (const [key, value] of map) {
			let mergeTag = "{" + key + "}";
			let regex = new RegExp(mergeTag, "gm");

			if (typeof value === "string") {
				result = result.replace(regex, value);
			}
		}

		return result;
	},
};

export default sms;
