


































import Vue from "vue";

// Data Classes
import GuestModel from "@/models/GuestModel";

// Components
import GuestComponent from "@/components/GuestComponent.vue";

export default Vue.extend({
	data() {
		return {
			isLoading: true as boolean,
			isSearching: false as boolean,
			msg: null as null | string,
			guestBidNumberInput: null as null | number,
			guests: null as null | GuestModel[],
			isPreregistering: false as boolean,
			isRegistering: false as boolean,
			bidNumber: null as null | number,
		};
	},

	created() {
		this.bidNumber = parseInt(this.$route.params.bidNumber);

		if (!!this.bidNumber) {
			this.isPreregistering = true;
		}
	},

	async mounted() {
		// If the bid number is present, then check if the guests are already registered
		if (!!this.bidNumber) {
			// Check if the guests are already registered
			let foundGuests = await GuestModel.fetchByBidNumber(this.bidNumber);

			// If the guests are already registered, then set the flag to true
			if (!!foundGuests && !!foundGuests.length) {
				this.isRegistering = true;

				// Set the registered guests
				for (const guest of foundGuests) {
					if (!guest.registered) {
						await guest.register().update();
					}
				}

				setTimeout(() => {
					// Set the registering flag to false
					this.isRegistering = false;
				}, 1500);
			}
		}

		// Set the loading flag to false
		this.isLoading = false;
	},

	methods: {
		async searchGuests() {
			this.guests = null;
			this.isSearching = true;

			if (!!this.guestBidNumberInput) {
				let foundGuests = await GuestModel.fetchByBidNumber(this.guestBidNumberInput);

				if (!!foundGuests && !!foundGuests.length) {
					this.guests = foundGuests;
					this.msg = null;
				} else {
					this.msg = "No guests found. Please verify that the bid number is correct.";
				}
			}

			this.isSearching = false;
		},

		copyPhone(phone: string) {
			if (this.guests) {
				this.guests.forEach((guest) => {
					guest.phone = phone;
				});
			}
		},

		reset() {
			this.guestBidNumberInput = null;
			this.guests = null;
		},
	},

	computed: {
		isValidData(): boolean {
			if (this.guests) {
				const invalidValues = this.guests.some((guest: GuestModel) => !guest.phone || guest.phone.length !== 10);

				return !invalidValues;
			}

			return false;
		},
	},

	components: {
		GuestComponent,
	},
});
