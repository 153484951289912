export default class GameModel {
	id: number;
	name: string;
	title: string;
	costPerPlay: null | number;
	totalPlays: null | number;

	constructor(id: number, name: string, title: string, costPerPlay: null | number = null, totalPlays: null | number = null) {
		this.id = id;
		this.name = name;
		this.title = title;
		this.costPerPlay = costPerPlay;
		this.totalPlays = totalPlays;
	}

	static async fetchAll(): Promise<GameModel[]> {
		const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/sideGames";
		const response = await fetch(endpoint, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});
		const data = await response.json();

		if (!!data.errors) return [];

		const games = data.sideGames.map((game: any) => new GameModel(game.id, game.name, game.title, game.costPerPlay, game.totalPlays));

		return games;
	}
}
