




















import Vue from "vue";
import { RawLocation } from "vue-router";
import GuestModel from "@/models/GuestModel";

export default Vue.extend({
	data() {
		return {
			isSearching: false as boolean,
			bidNumberInput: null as null | number,
		};
	},

	methods: {
		async findGuest() {
			if (this.bidNumberInput) {
				this.isSearching = true;

				let guests = await GuestModel.fetchByBidNumber(this.bidNumberInput);

				if (!!guests && !!guests.length) {
					this.$router.push(({ name: "CheckoutDetail", params: { bidNumber: this.bidNumberInput }, query: { admin: 1 } } as unknown) as RawLocation);
				} else {
					this.$toasted.error("Sorry, we were unable to find a guest with Bid # " + this.bidNumberInput + ".");
				}

				this.isSearching = false;
			}
		},
	},
});
