const filters = {
	formatStripeCurrency(value: any) {
		return 100 * parseFloat(parseFloat(value).toFixed(2));
	},

	formatCurrencyStr(value: any) {
		return "$ " + parseFloat(value).toFixed(2);
	},

	currency(value: any) {
		return `$${Intl.NumberFormat("en-US").format(value)}`;
	},
};

export default filters;
