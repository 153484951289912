import moment from "moment";

export default class MessageModel {
	id: number;
	message: string;
	created: string;
	group: string;
	count: number;
	from: string;

	constructor(args: any) {
		this.id = args.id || null;
		this.message = args.message || null;
		this.created = args.created || null;
		this.group = args.group || null;
		this.count = args.count || null;
		this.from = args.from || null;
	}

	async create() {
		const endpoint = process.env.VUE_APP_SHEETY_ENDPOINT + "/messageHistory";

		const data = {
			messageHistory: {
				...this,
				created: moment().format(),
			},
		};

		const response = await fetch(endpoint, {
			method: "POST",
			cache: "no-store",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const json = await response.json();

		if (!!json.error) throw new Error(json.error);

		return new MessageModel(json.messageHistory);
	}

	static async fetchBy(key: null | string = null, value: null | string = null) {
		let queries = [] as string[];
		let endpoint = `${process.env.VUE_APP_SHEETY_ENDPOINT}/messageHistory`;

		if (!!key && !!value) {
			queries.push(`?filter[${key}]=${value}`);
		}

		if (queries.length > 0) {
			endpoint += `?${queries.join("&")}`;
		}

		const response = await fetch(endpoint, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.VUE_APP_BEARER_TOKEN,
			},
		});

		const json = await response.json();

		if (!!json.error) throw new Error(json.error);

		return json.messageHistory.map((message: any) => new MessageModel(message));
	}
}
