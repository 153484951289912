import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

//import * as Sentry from "@sentry/browser";
//import * as Integrations from "@sentry/integrations";

/*
Sentry.init({
	dsn: "https://0dda13daf715439a887fd16974e54524@sentry.io/3949553",
	integrations: [new Integrations.Vue({ Vue, attachProps: true })]
});
*/

Vue.config.productionTip = false;

new Vue({
	store,
	router,
	created() {
		const scripts = ["https://kit.fontawesome.com/495c6a1199.js"];

		scripts.forEach((scriptSrc: string) => {
			let script = document.createElement("script");
			script.setAttribute("src", scriptSrc);
			document.head.appendChild(script);
		});
	},
	render: (h) => h(App),
}).$mount("#app");
